import {useUser} from "@/Store/User.js";
import {storeToRefs} from "pinia";

export default {
    install: (app, options) => {
        const userStore = useUser(),
            {usersOnline, onlineChannel} = storeToRefs(userStore)

        const updateChannel = user => {
            if (typeof window === 'undefined') return;
            if (user) {
                if(onlineChannel.value == null) {
                    onlineChannel.value = joinChannel()
                }
            } else {
                if (onlineChannel.value != null) {
                    Echo.leave('user_online')
                    onlineChannel.value = null
                }
            }
        };
        app.provide('updateChannel', updateChannel);

        const joinChannel = () => {
            if (typeof window === 'undefined') return;
            return Echo.join('user_online')
                .here((users) => {
                    usersOnline.value = users.reduce((acc, cur) => {
                        acc[cur.id] = cur;
                        return acc;
                    }, {});
                })
                .joining((user) => {
                    // user: {id: 123}
                    usersOnline.value[user.id] = user
                })
                .leaving((user) => {
                    // user: {id: 123}
                    delete usersOnline.value[user.id]
                })
        }
        updateChannel(options?.user)
    }
}
